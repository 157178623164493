<template>
  <v-row>
    <v-col
      cols="12"
      class="pt-0"
    >
      <div class="form__section-title">
        Szczegóły płatności
      </div>
    </v-col>

    <v-col
      cols="8"
      class="py-0"
    >
      <PaymentType
        :disabled="disabled"
        :payment-data="paymentData"
        :course-type="courseType"
        cols="7/5"
        @update="$emit('update',$event)"
      />
    </v-col>

    <v-col cols="4">
      <v-autocomplete
        :value="paymentData.settlementType"
        outlined
        :items="filteredSettlementTypes"
        :rules="[rules.required]"
        label="Typ dokumentu"
        placeholder="Wybierz typ dokumentu"
        append-icon="mdi-chevron-down"
        dense
        :disabled="disabled"
        @input="emitUpdate('payment.settlementType', $event)"
      />
    </v-col>
    <v-col cols="2">
      <span class="discount-checkbox-label">Dodaj rabat</span>
      <v-checkbox
        v-model="showDiscountFields"
        hide-details
        class="mt-1 px-7"
        :disabled="disabled"
        @change="removeDiscount"
      />
    </v-col>

    <v-col
      v-if="!isTransportIncluded"
      cols="3"
    >
      <v-text-field
        outlined
        label="Rabat netto [zł]"
        :value="paymentData.discount"
        type="number"
        validate-on-blur
        :disabled="!showDiscountFields || disabled"
        :rules="[rules.money, rules.lowerThan(10000001)]"
        @input="emitUpdate('payment.discount', $event)"
        @wheel="$event.target.blur()"
        placeholder="0zł"
      />
    </v-col>

    <v-col
      v-if="!isTransportIncluded"
      cols="7"
    >
      <v-text-field
        outlined
        label="Uzasadnienie rabatu"
        :disabled="!showDiscountFields || disabled"
        :value="paymentData.discountJustification"
        @input="emitUpdate('payment.discountJustification', $event)"
        placeholder="Wpisz uzasadnienie rabatu"
      />
    </v-col>

    <v-col
      cols="12"
      class="py-0"
    >
      <PaymentRow
        :net-amount="paymentData.debrisNetValue"
        :label="debrisGenitive"
        :tax-value="paymentData.vatPercentage"
        :disabled="disabled"
        @update="updatePayment('debris',$event)"
      />
    </v-col>

    <v-col
      cols="12"
      v-if="isTransportIncluded"
      class="py-0"
    >
      <PaymentRow
        :net-amount="paymentData.transportNetValue"
        label="transportu"
        :tax-value="paymentData.vatPercentage"
        :disabled="disabled"
        @update="updatePayment('transport',$event)"
      />
    </v-col>

    <v-col
      cols="12"
      class="pt-0 d-flex justify-space-between"
    >
      <div class="input-label">
        <div v-if="locationDiscount">
          Rabat lokalizacji klienta: {{ locationDiscount }} zł
        </div>
        <div v-if="suggestedTransportPricePLN">
          Sugerowana cena transportu: {{ suggestedTransportPricePLN }} zł
        </div>
      </div>
      <div class="input-label mr-2">
        Do zapłaty: {{ amountLeftToPay }} zł
      </div>
    </v-col>
  </v-row>
</template>

<script>
import rules from '../../../utils/validators'
import PaymentRow from './PaymentRow'
import PaymentType from './PaymentType'
import { parseFloatNumber, getGrossValue } from '../../../utils'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    PaymentRow,
    PaymentType,
  },
  props: {
    paymentData: {
      type: Object,
      required: true
    },
    courseType: {
      type: String,
      default: ''
    },
    clientType: {
      type: String,
      default: ''
    },
    locationDiscount: {
      type: Number,
      default: null
    },
    distance: {
      type: Number,
      default: null
    },
    aggregateAmount: {
      type: [Number, String],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules,
      showDiscountFields: false
    }
  },
  computed: {
    ...mapState({
      options: state => state.core.filters,
      department: state => state.core.department,
      transportPrices: state => {
        return [...state.transportPrices.items].sort((a, b) => a.maximumDistance - b.maximumDistance)
      }
    }),
    suggestedTransportPricePLN () {
      return this.distance && this.transportPrices?.find(price => price.maximumDistance / 1000 > this.distance)?.price / 100
    },
    isTransportIncluded () {
      return ['Odbiór surowca', 'Transport'].includes(this.courseType)
    },
    debrisGenitive () {
      if (!this.isTransportIncluded) return ''
      return this.courseType === 'Transport' ? 'kruszywa za tonę' : 'surowca'
    },
    taxValue () {
      if (!this.paymentData?.vatPercentage) {
        const tax = this.isTransportIncluded ? 23 : 8
        this.emitUpdate('payment.vatPercentage', tax)
        return tax
      } else {
        return this.paymentData.vatPercentage
      }
    },
    amountLeftToPay () {
      const { discount, debrisNetValue, transportNetValue } = this.paymentData
      const debrisValue = this.courseType === 'Transport' ? parseFloatNumber(debrisNetValue) * (+this.aggregateAmount || 1) : parseFloatNumber(debrisNetValue)
      const value = this.getGrossValue(debrisValue + parseFloatNumber(transportNetValue) - parseFloatNumber(discount))
      return value > 0 ? value.toFixed(2) : 0
    },
    filteredSettlementTypes() {
      return this.options.clientSettlementTypes.filter((type) => {
        switch (this.clientType) {
          case 'Firma':
            return type.company
          case 'Osoba fizyczna':
            return type.individual
          default:
            return true
        }
      }).map(type => type.type)
    }
  },
  watch: {
    courseType: {
      immediate: true,
      handler (type) {
        if (type === 'Transport') {
          this.$nextTick(() => {
            this.emitUpdate('payment.paymentType', 'Gotówka przy podstawieniu')
          })
          if (this.suggestedTransportPricePLN) {
            this.updateTransportValues(this.suggestedTransportPricePLN)
          }
        }
      },
    },
    suggestedTransportPricePLN (price) {
      if (this.courseType === 'Transport') {
        this.updateTransportValues(price)
      }
    },
    'paymentData.discount' (value) {
      if (value > 0) {
        this.showDiscountFields = true
      }
    }
  },
  mounted () {
    this.getTransportPrices({ departentId: this.department.id })
  },
  methods: {
    ...mapActions({
      getTransportPrices: 'transportPrices/getItems'
    }),
    emitUpdate (key, value) {
      this.$emit('update', { key, value })
    },
    updateTransportValues(netValue, grossValue) {
      if (!grossValue) grossValue = this.getGrossValue(netValue)

      this.emitUpdate('payment.transportNetValue', netValue)
      this.emitUpdate('payment.transportGrossValue', grossValue)
    },
    updatePayment (type, values) {
      if (type === 'debris') {
        this.emitUpdate('payment.origin', values.origin)
        this.emitUpdate('payment.debrisNetValue', values.netValue)
        this.emitUpdate('payment.debrisGrossValue', values.grossValue)
      } else {
        this.updateTransportValues(values.netValue, values.grossValue)
      }
    },
    getGrossValue (value) {
      return getGrossValue(value, this.taxValue)
    },
    removeDiscount(value) {
      if (!value) {
        this.emitUpdate('payment.discount', 0)
      }
    }
  }
}
</script>

<style scoped>
.discount-checkbox-label {
  position: absolute;
  transform: translate(5px,-21px);
  color: black;
  font-weight: 700;
  font-size: 12px;
}
</style>
