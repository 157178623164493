<template>
  <v-row>
    <v-col
      cols="12"
      class="pt-0 mb-2"
    >
      <div class="d-flex align-center">
        <span class="input-label mr-4">Wprowadzana Wartość</span>
        <v-radio-group
          v-model="isNetInputActive"
          :disabled="disabled"
          row
          dense
          hide-details
          class="pt-0"
        >
          <v-radio
            label="Netto"
            :value="true"
          />
          <v-radio
            label="Brutto"
            :value="false"
          />
        </v-radio-group>
      </div>
    </v-col>

    <v-col
      cols="5"
      v-if="isNetInputActive"
      class="pb-0"
    >
      <v-text-field
        ref="netValueInput"
        outlined
        :label="'Cena netto ' + label + ' [zł]'"
        v-model.number="netValue"
        type="number"
        validate-on-blur
        :max="maxValue"
        :rules="[rules.required,rules.money, rules.lowerThan(maxValue)]"
        :disabled="disabled"
        @wheel="$event.target.blur()"
        placeholder="Wpisz cenę netto"
      />
    </v-col>
    <v-col
      cols="5"
      class="pt-0"
      v-else
    >
      <div class="input-label">
        Cena netto {{ label }} [zł]
      </div>
      <div>{{ +netAmount | significantFigures }} zł</div>
    </v-col>

    <v-col
      cols="2"
      class="pt-0"
    >
      <div class="input-label">
        Vat
      </div>
      <div>{{ taxValue }}%</div>
    </v-col>

    <v-col
      cols="5"
      v-if="!isNetInputActive"
      class="pb-0"
    >
      <v-text-field
        ref="grossValueInput"
        outlined
        :label="'Cena brutto ' + label + ' [zł]'"
        v-model.number="grossValue"
        type="number"
        validate-on-blur
        :max="maxValue"
        :rules="[rules.required,rules.money, rules.lowerThan(maxValue)]"
        :disabled="disabled"
        @wheel="$event.target.blur()"
        placeholder="Wpisz cenę brutto"
      />
    </v-col>
    <v-col
      cols="5"
      class="pt-0"
      v-else
    >
      <div class="input-label">
        Cena brutto {{ label }} [zł]
      </div>
      <div>{{ getGrossValue(netAmount) | significantFigures }} zł</div>
    </v-col>
  </v-row>
</template>

<script>
import rules from '../../../utils/validators'
import { getNetValue, getGrossValue } from '../../../utils'

export default {
  filters: {
    significantFigures (value) {
      return value ? value.toFixed(2) : '0.00'
    }
  },
  props: {
    netAmount: {
      type: [Number, String, Object],
      default: 0,
    },
    label: {
      type: String,
      default: ''
    },
    taxValue: {
      type: Number,
      required: true
    },
    startWithGross: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    focusInput: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules,
      isNetInputActive: true,
      localGrossValue: 0,
      localNetValueSetByGrossValue: 0,
      maxValue: 10000001
    }
  },
  computed: {
    grossValue: {
      get () {
        return this.localGrossValue
      },
      set (grossValue) {
        this.localGrossValue = grossValue
        this.localNetValueSetByGrossValue = this.getNetValue(grossValue)
        this.$emit('update', {
          netValue: this.getNetValue(grossValue),
          grossValue,
          origin: 'R' // price origin is set manually representet by "Ręcznie"
        })
      }
    },
    netValue: {
      get () {
        return this.netAmount
      },
      set (netValue) {
        this.$emit('update', {
          grossValue: this.getGrossValue(netValue),
          netValue,
          origin: 'R' // price origin is set manually representet by "Ręcznie"
        })
      }
    }

  },
  watch: {
    isNetInputActive (net) {
      if (!net) this.localGrossValue = this.getGrossValue(this.netAmount)?.toFixed(2)
    },
    netAmount (value) {
      if (!this.isNetInputActive && value !== this.localNetValueSetByGrossValue) {
        this.localGrossValue = this.getGrossValue(value)?.toFixed(2)
      }
    }
  },
  created () {
    if (this.startWithGross) this.isNetInputActive = false

    if (this.focusInput) {
      this.$nextTick().then(() => {
        this.isNetInputActive ? this.$refs.netValueInput.focus() : this.$refs.grossValueInput.focus()
      })
    }
  },
  methods: {
    getNetValue (value) {
      return getNetValue(value, this.taxValue)
    },
    getGrossValue (value) {
      return getGrossValue(value, this.taxValue)
    }
  }
}
</script>
